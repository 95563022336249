/* TED player */
.mejs-container.mejs-ted {

}
.mejs-ted .mejs-controls {
	background: #eee;
	height: 65px;
}

.mejs-ted .mejs-button,
.mejs-ted .mejs-time {
	position: absolute;
	background: #ddd;
}
.mejs-ted .mejs-controls .mejs-time-rail .mejs-time-total {
	background-color: none;
	background: url(../images/icons/controls-ted.png) repeat-x 0 -52px;
	height: 6px;
}
.mejs-ted .mejs-controls .mejs-time-rail .mejs-time-buffering {
	height: 6px;
}
.mejs-ted .mejs-controls .mejs-time-rail .mejs-time-loaded {
	background-color: none;
	background: url(../images/icons/controls-ted.png) repeat-x 0 -52px;
	width: 0;
	height: 6px;
}
.mejs-ted .mejs-controls .mejs-time-rail .mejs-time-current {
	width: 0;
	height: 6px;
	background-color: none;
	background: url(../images/icons/controls-ted.png) repeat-x 0 -59px;
}
.mejs-ted .mejs-controls .mejs-time-rail .mejs-time-handle  {
	display: block;
	margin: 0;
	width: 14px;
	height: 21px;
	top: -7px;
	border: 0;
	background: url(../images/icons/controls-ted.png) no-repeat 0 0;	
}
.mejs-ted .mejs-controls .mejs-time-rail .mejs-time-float {	
	display: none;
}
.mejs-ted .mejs-controls .mejs-playpause-button  {
	top: 29px;
	left: 9px;
	width: 49px;
	height: 28px;
}
.mejs-ted .mejs-controls .mejs-playpause-button  button {
	width: 49px;
	height: 28px;
	background: url(../images/icons/controls-ted.png) no-repeat -50px -23px;
	margin: 0;
	padding: 0;
}
.mejs-ted .mejs-controls .mejs-pause button  {
	background-position: 0 -23px;
}

.mejs-ted .mejs-controls .mejs-fullscreen-button  {
	top: 34px;
	right: 9px;
	width: 17px;
	height: 15px;
	background : none;
}
.mejs-ted .mejs-controls .mejs-fullscreen-button  button {
	width: 19px;
	height: 17px;
	background: transparent url(../images/icons/controls-ted.png) no-repeat 0 -66px;
	margin: 0;
	padding: 0;
}
.mejs-ted .mejs-controls .mejs-unfullscreen  button {
	background: transparent url(../images/icons/controls-ted.png) no-repeat -21px -66px;
	margin: 0;
	padding: 0;
}
.mejs-ted .mejs-controls .mejs-volume-button  {
	top: 30px;
	right: 35px;
	width: 24px;
	height: 22px;
}
.mejs-ted .mejs-controls .mejs-mute button {
	background: url(../images/icons/controls-ted.png) no-repeat -15px 0;
	width: 24px;
	height: 22px;
	margin: 0;
	padding: 0;
}
.mejs-ted .mejs-controls .mejs-unmute button {
	background: url(../images/icons/controls-ted.png) no-repeat -40px 0;
	width: 24px;
	height: 22px;
	margin: 0;
	padding: 0;	
}
.mejs-ted  .mejs-controls .mejs-volume-button .mejs-volume-slider {
	background: #fff;
	border: solid 1px #aaa;
	border-width: 1px 1px 0 1px;
	width: 22px;
	height: 65px;
	top: -65px;
}
.mejs-ted  .mejs-controls .mejs-volume-button .mejs-volume-total {
	background: url(../images/icons/controls-ted.png) repeat-y -41px -66px;
	left: 8px;
	width: 6px;
	height: 50px;
}
.mejs-ted  .mejs-controls .mejs-volume-button .mejs-volume-current {
	left: 8px;
	width: 6px;	
	background: url(../images/icons/controls-ted.png) repeat-y -48px -66px;
	height: 50px;
}

.mejs-ted  .mejs-controls .mejs-volume-button .mejs-volume-handle {
	display: none;
}

.mejs-ted .mejs-controls .mejs-time span {
	color: #333;
}
.mejs-ted .mejs-controls .mejs-currenttime-container  {
	position: absolute;
	top: 32px;
	right: 100px;
	border: solid 1px #999;
	background: #fff;
	color: #333;
	padding-top: 2px;
	border-radius: 3px;
	color: #333;
}
.mejs-ted .mejs-controls .mejs-duration-container  {

	position: absolute;
	top: 32px;
	right: 65px;
	border: solid 1px #999;
	background: #fff;
	color: #333;
	padding-top: 2px;
	border-radius: 3px;
	color: #333;
}

.mejs-ted .mejs-controls .mejs-time  button{
	color: #333;
}
.mejs-ted .mejs-controls .mejs-captions-button {
	display: none;
}
/* END: TED player */


/* WMP player */
.mejs-container.mejs-wmp {

}
.mejs-wmp .mejs-controls {
	background: transparent url(../images/icons/controls-wmp-bg.png) center 16px no-repeat;
	height: 65px;
}

.mejs-wmp .mejs-button,
.mejs-wmp .mejs-time {
	position: absolute;
	background: transparent;
}
.mejs-wmp .mejs-controls .mejs-time-rail .mejs-time-total {
	background-color: transparent;
	border: solid 1px #ccc;
	height: 3px;
}
.mejs-wmp .mejs-controls .mejs-time-rail .mejs-time-buffering {
	height: 3px;
}
.mejs-wmp .mejs-controls .mejs-time-rail .mejs-time-loaded {
	background-color: rgba(255,255,255,0.3);
	width: 0;
	height: 3px;
}
.mejs-wmp .mejs-controls .mejs-time-rail .mejs-time-current {
	width: 0;
	height: 1px;
	background-color: #014CB6;
	border: solid 1px #7FC9FA;
	border-width: 1px 0;
	border-color: #7FC9FA #fff #619FF2 #fff;
}
.mejs-wmp .mejs-controls .mejs-time-rail .mejs-time-handle  {
	display: block;
	margin: 0;
	width: 16px;
	height: 9px;
	top: -3px;
	border: 0;
	background: url(../images/icons/controls-wmp.png) no-repeat 0 -80px;	
}
.mejs-wmp .mejs-controls .mejs-time-rail .mejs-time-float {	
	display: none;
}
.mejs-wmp .mejs-controls .mejs-playpause-button  {
	top: 10px;
	left: 50%;
	margin: 10px 0 0 -20px;	
	width: 40px;
	height: 40px;
	
}
.mejs-wmp .mejs-controls .mejs-playpause-button  button {
	width: 40px;
	height: 40px;
	background: url(../images/icons/controls-wmp.png) no-repeat 0 0;	
	margin: 0;
	padding: 0;
}
.mejs-wmp .mejs-controls .mejs-pause button  {
	background-position: 0 -40px;
}

.mejs-wmp .mejs-controls .mejs-currenttime-container  {
	position: absolute;
	top: 25px;
	left: 50%;
	margin-left: -93px;
}
.mejs-wmp .mejs-controls .mejs-duration-container  {
	position: absolute;
	top: 25px;
	left: 50%;
	margin-left: -58px;
}


.mejs-wmp .mejs-controls .mejs-volume-button  {
	top: 32px;
	right: 50%;
	margin-right: -55px;
	width: 20px;
	height: 15px;
}
.mejs-wmp .mejs-controls .mejs-volume-button button {
	margin: 0;
	padding: 0;
	background: url(../images/icons/controls-wmp.png) no-repeat -42px -17px;
	width: 20px;
	height: 15px;
}
.mejs-wmp .mejs-controls .mejs-unmute button {
	margin: 0;
	padding: 0;
	background: url(../images/icons/controls-wmp.png) no-repeat -42px 0;
	width: 20px;
	height: 15px;
}
.mejs-wmp .mejs-controls .mejs-volume-button .mejs-volume-slider {
	background: rgba(102,102,102,0.6);
}

.mejs-wmp .mejs-controls .mejs-fullscreen-button  {
	top: 32px;
	right: 50%;
	margin-right: -82px;
	width: 15px;
	height: 14px;
}
.mejs-wmp .mejs-controls .mejs-fullscreen-button  button {
	margin: 0;
	padding: 0;
	background: url(../images/icons/controls-wmp.png) no-repeat -63px 0;
	width: 15px;
	height: 14px;
}
.mejs-wmp .mejs-controls .mejs-captions-button {
	display: none;
}
/* END: WMP player */



